$(function(){


    //Create date styles for sidebar recently announced
    var recentlyAnnouncedDate = [];

    //$('.recently-announced li, .event-full .date').each(function(i){
    //    recentlyAnnouncedDate[i] = $(this).find('span').text().split(" ");
    //    $(this).find('span').html('<span class="month">' + recentlyAnnouncedDate[i][1] + '</span><span class="day">' + recentlyAnnouncedDate[i][2] + '</span>');
    //})
    //
    ////calendar mobile dates
    //$('.posters td:nth-child(2n+1) span').addClass('right');
    //$('.posters td:nth-child(2n+0) span').addClass('left');
    //
    //$('.posters tr:nth-child(2n+1)').addClass('odd');
    //$('.posters tr:nth-child(2n+0)').addClass('even');
    //
    ////cross browser nav fix
    //$('header#top ul.left li:last-child').css('background-image','none');
    //$('header#top ul.right li:first-child').css('background-image','none');
    //
    //parallax
    $(window).stellar({
        horizontalScrolling: false,
        verticalOffset: 40
    });

    //
    ////fix the content bg height
    //$(window).load(function(){
    //    setTimeout(fixGal,300);
    //});
    //
    //
    //function fixGal(){
    //    $('#content-bg').css('height',$('#main-content').height() - 145);
    //}
    //
    //
    //$(window).resize(function(){
    //    $('#content-bg').css('height',$('#main-content').height() - 145);
    //});
    //
    ////add shadowbox to gallery
    //$('.gallery a').attr('rel','shadowbox[gal]');
    //
    //$(window).load(function(){
    //    Shadowbox.init();
    //});
    //
    //
    ////accordian
    //$('.accordian p').hide();
    //
    //$('.accordian h4').click(function(){
    //
    //    $(this).prev('span').toggleClass('open').siblings("span").removeClass('open');
    //    $(this).next('p').slideToggle().siblings("p:visible").slideUp("slow");
    //
    //});
    //
    //$('.accordian h3').click(function(){
    //
    //    if($(this).next('p').is(':visible')){
    //        $('.accordian p').hide();
    //        $(this).removeClass('open');
    //        $('#content-bg').css('height',$('#main-content').height() - 145);
    //    }
    //    else {
    //        $(this).addClass('open');
    //        $('.accordian p').hide();
    //        $(this).next('p').show();
    //        $('#content-bg').css('height',$('#main-content').height() - 145);
    //    }
    //
    //});
    //
    ////sold out
    //$('a.buy').click(function(e){
    //    if($(this).children().length > 0){
    //        e.preventDefault();
    //    }
    //});


});